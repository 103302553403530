import { API } from "../libs/client";


/**
 * @param {{params: {status:number}}} params
 * @param {string} projectId
 * @returns
 */

export const loadListChannel = async (projectId:string, params = {}) => {
    const uri = `/projects/${projectId}/channels/list`;
    const res = await API.get(uri, { params });
    return res.data;
};

/**
 * @param {string} projectId
 * @returns
 */

export const getListGroupChannels = async (projectId:string, params = {}) => {
    const uri = `projects/${projectId}/group-channels/list`;
    const res = await API.get(uri, { params });
    return res.data;
  };