import { useTranslation } from "react-i18next";
import IconConversationScript from "../assets/svg/LeftMenuSetting/IconConversationScript";
import {useLocation , Link} from "react-router-dom";

export default function LeftMenu() {
  const location = useLocation();
  const {t} = useTranslation();
  const listLeftMenu = [
    {
      id: 1,
      name: t('script-setting.trigger'),
      path: '/setting/conversation-script'
    },
    {
      id: 2,
      name: t('tag.trigger'),
      path: '/setting/conversation-tag'
    },
    {
      id: 3,
      name: t('sound-setting.header'),
      path: '/setting/sound-notification'
    }
  ]
  return (
    <div className={`w-[250px] 2xl:w-[280px] h-max-screen bg-white pt-4`}>
      {
        listLeftMenu.map((item, key) => {
          return <Link to={item.path} key={key} className={`flex ${location.pathname === item.path ? "bg-gray-100 font-medium" : ""} gap-2 items-center py-4 px-7 hover:bg-gray-100 hover:font-medium text-base cursor-pointer`}>
            <div className={`pt-1`}><IconConversationScript isActive={location.pathname === item.path}/></div>
            <div>{item.name}</div>
          </Link>
        })
      }
    </div>
  )
}