import IconFacebook from "../../assets/svg/IconFacebook";
import IconInstagram from "../../assets/svg/IconInstagram";
import { PLATFORM } from "../../utils/constants/platform";

export const Icon = {
  [PLATFORM.PLATFORM_FACEBOOK]: <IconFacebook />,
  [PLATFORM.PLATFORM_FACEBOOK_GROUP]: <IconFacebook />,
  [PLATFORM.PLATFORM_INSTAGRAM]: <IconInstagram />,
  [PLATFORM.PLATFORM_TIKTOK]: <></>,
  [PLATFORM.PLATFORM_ZALO_OA]: <></>,
};

export const IconPlatform = ({
  platform,
}: {
  platform:
    | PLATFORM.PLATFORM_FACEBOOK
    | PLATFORM.PLATFORM_INSTAGRAM
    | PLATFORM.PLATFORM_TIKTOK
    | PLATFORM.PLATFORM_ZALO_OA
    | PLATFORM.PLATFORM_FACEBOOK_GROUP;
}) => {
  return Icon[platform];
};
