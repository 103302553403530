import Cookies from "js-cookie";

export const getUserInfor = () => {
  return JSON.parse(Cookies.get('userInfor') || "{}")
}

export const checkIsInstalledExt = () => {
  const domExt = document.querySelectorAll<HTMLInputElement>(
    '[name="9hub"]'
  );
  let isInstalled = false;
  if (domExt && domExt.length) {
    domExt.forEach((item) => {
      if (item.value === 'pnggbmbenebnjldiocnpklocljgageeo') {
        isInstalled = true;
        return;
      }
    })
  }
  return isInstalled
}

export const getDomainForCookie = () => {
  switch (getDomainApp()) {
    case "https://chat.so9.vn":
      return process.env.REACT_APP_COOKIE_DOMAIN;
    case "https://chat.so9.io":
      return process.env.REACT_APP_COOKIE_DOMAIN_GLOBAL;
    default:
      return process.env.REACT_APP_COOKIE_DOMAIN;
  }
}


export const getRedirectUrl = () => {
  switch (getDomainApp()) {
    case "https://chat.so9.vn":
      return process.env.REACT_APP_CHAT_URL;
    case "https://chat.so9.io":
      return process.env.REACT_APP_CHAT_URL_GLOBAL;
    default:
      return process.env.REACT_APP_CHAT_URL;
  }
}

export const getDomainLogin = () => {
  switch (getDomainApp()) {
    case "https://chat.so9.vn":
      return "https://app.so9.vn";
    case "https://chat.so9.io":
      return "https://app.so9.io";
    default:
      return "https://app.so9.vn";
  }
}

export const getDomainApp = () => {
  return window.location.origin;
}