import NavItem from "../components/navbar/NavItem";
import IconSetting from "../components/icons/IconSetting";
import IconAnalyst from "../components/icons/IconAnalyst";
import CountMessage from "../components/shared/CountMessage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getConversationUnread } from "../api/conversation";
import { getUserInfor } from "../helper/common";
import { Menu,Avatar, message, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import En_icon from "../components/icons/united-kingdom-flag-icon.png"
import Vi_icon from "../components/icons/vietnam-flag-icon.png"

export default function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const [totalUnreadMessage, setTotalUnreadMessage] = useState(0)
  const [curentLang, setCurentLang] = useState(i18n.language);
  const [avatarError, setAvatarError] = useState<boolean>(false);


  const userAvatar = getUserInfor();
  const headerList = [
    {
      id: 1,
      path: '/',
      name: t("menu.conversation"),
      preIcon: totalUnreadMessage > 0 ? <CountMessage total={totalUnreadMessage} /> : null
    },
    // {
    //   id: 2,
    //   path: '/analyst',
    //   name: t("menu.analystic"),
    //   preIcon: <IconAnalyst />
    // },
    {
      id: 3,
      path: '/campaign/bot-auto',
      name: t("menu.campaign"),
      preIcon: <IconAnalyst />
    },
    {
      id: 4,
      path: '/setting/conversation-script',
      name: t("menu.setting"),
      preIcon: <IconSetting />
    }
  ];

  const handleChangleLang = (type: string) => {
    setCurentLang(type);
    i18n.changeLanguage(type);
    localStorage.setItem('chatLang', type);
  }

  const menu = (
    <Menu>
      <Menu.Item key="en"
        onClick={() => { handleChangleLang("en") }}
      >
        <div className="flex gap-1">
          <img src={En_icon}
            style={{ height: "24px", objectFit: "cover" }}
          ></img>
          {t("lang.en")}
        </div>
      </Menu.Item>
      <Menu.Item key="vi"
        onClick={() => { handleChangleLang("vi") }}
      >
        <div className="flex gap-1">
          <img src={Vi_icon}
            style={{ height: "24px", objectFit: "cover" }}
          ></img>
          {t("lang.vi")}
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleFetchCountUnreadMessage = async () => {
    try {
      const getTotalMessage = await getConversationUnread(getUserInfor().last_project_active)
      if (getTotalMessage) {
        setTotalUnreadMessage(getTotalMessage)
      }
    } catch (e) {
      message.error(t("menu.unread-error"))
      setTotalUnreadMessage(0)
    }
  }
  const getAvatarName = (name : string) => {
    if (!name) {
      return "U";
    }
    // Chia chuỗi thành các từ
    const words = name.split(" ");
    const last = words[words.length - 1].slice(0, 1);
    return `${last}`.toUpperCase();

  }

  useEffect(() => {
    handleFetchCountUnreadMessage()
  }, []);
  return (
    <div className={`bg-header flex fixed top-0 w-full  `} style={{ height: "55px" }}>
      <div className={`w-[224px] flex items-center cursor-pointer`} onClick={() => navigate('/')}>
        <img width={141} alt={''} src={'/chat/logo-new.png'} />
      </div>
      <div className="flex w-full"
        style={{ justifyContent: "space-between" }}
      >
        <div className="flex">
          {
            headerList.map((item, key) => {
              return <NavItem key={key} path={item.path} preIcon={item.preIcon} title={item.name} />
            })
          }
        </div>
        <div className="flex items-center"
          style={{ marginRight: "16px" }}
        >
          <Dropdown overlay={menu} className="me-3">
            <button className="ant-dropdown-link flex gap-2" onClick={e => e.preventDefault()}
              style={{ color: "#ffff", fontWeight: "600" }}
            >
              <img src={curentLang === "en" ? En_icon : Vi_icon}
                style={{ height: "24px", objectFit: "cover" }}
              ></img>
            </button>
          </Dropdown>
          {userAvatar &&
            (
              <>
              {avatarError ? (
                <>
                  <div>
                  <Avatar style={{ 
                    backgroundColor: '#fde3cf',
                    border : "rgba(103, 131, 199, 1) solid 1px",
                    // background: 'linear-gradient(rgba(20, 34, 67, 1), rgba(240, 57, 137, 1))',
                   color: '#f56a00' }}>{getAvatarName(userAvatar?.name)}</Avatar>
                   
                  </div>
                </>
              ) : 
              (
                <img
                src={userAvatar?.avatar}
                style={{ width: "32px", height: "32px", borderRadius: "50%", objectFit: "cover" ,  border : "rgba(103, 131, 199, 1) solid 1px",}}
                onError={() => { setAvatarError(true) }}
              />
              )}
              </>
            )
          }
        </div>
      </div>

    </div>
  )
}
